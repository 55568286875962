<template>
  <div class="ant-card">
    <div class="document">
      <div class="header">
        <span>济南综保控股集团有限公司文件处理单</span>
      </div>
      <div class="content-table">
        <div class="content-table-item">
          <div class="left-column">
            <span>领导批示</span>
          </div>
          <div style="flex: 1; height: 100%;">
          </div>
        </div>
        <div class="content-table-item2">
          <div class="left-column">
            <span>拟办意见</span>
          </div>
          <div style="flex: 1; height: 100% ;">
          </div>
        </div>
        <div class="content-table-item3">
          <div class="left-column">
            <span>内容摘要</span>
          </div>
          <div style="flex: 1; height: 100% ;">
          </div>
        </div>
        <div class="content-table-item4">
          <div class="file-title">
            <span>文件&#10;标题</span>
          </div>
          <div style="flex: 1; height: 100% ;">
          </div>
        </div>
        <div class="content-table-item5">
          <div style="border-right: 0.1mm solid red;">
            <div class="left-column2">
              <span>来文&#10;单位</span>
            </div>
            <div style="width: 70.7mm; height: 100%;">
            </div>
          </div>
          <div style="border-right: 0.1mm solid red; display: flex; width: 45mm;">
            <div class="left-column2" style="width: 15mm;">
              <span>收文&#10;时间</span>
            </div>
            <div style="flex: 1 height: 100%; width: 30mm;">
            </div>
          </div>
          <div style="display: flex; width: 28.7mm;">
            <div class="left-column2" style="width: 14.6mm">
              <span>文号</span>
            </div>
            <div style="width: 14.1mm; height: 100%;">
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <span>编号：20XX00XX</span>
        <span>经办人：XXX</span>
        <span>电话：XXXXXX</span>
      </div>
    </div>
  </div>

</template>
<script>

export default {
  name: 'OD',
  components: {
  },
  data () {
    return {
    }
  },
  methods: {
    inputData (type) {
      this.inputType = type
      this.inputVisible = true
    },
    handleOk () {
    },
    handleAddCancel () {
      this.inputVisible = false
      this.confirmLoading = false
    },
    handleSignatureChange1 (signatureData) {
      this.$refs.signPad1.clear()
    },
    handleSignatureChange2 (signatureData) {
      this.$refs.signPad2.clear()
    }
  }
}
</script>

<style scoped>
.ant-card {
  padding: 0;
  margin: 0;
  width: 210mm;
  height: 297mm;
}

.document {
  padding: 37mm 26mm 35mm 28mm;
  color: red;
  font-family: FangSong, sans-serif;
  font-size: 15pt;
}

.header {
  text-align: center;
  font-size: 26pt;
  color: red;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 方正小标宋简体, sans-serif;
}

.content-table {
  width: 100%;
  display: flex;
  border-collapse: collapse;
  flex-direction: column;
}

.content-table-item {
  display: flex;
  width: 100%;
  height: 49.6mm;
  border-top: 1px solid red;
  border-bottom: 1px solid red;
}

.content-table-item2 {
  display: flex;
  width: 162.5mm;
  height: 46.4mm;
  border-bottom: 1px solid red;
}

.content-table-item3 {
  display: flex;
  width: 162.5mm;
  height: 48.3mm;
  border-bottom: 1px solid red;
}

.content-table-item4 {
  display: flex;
  width: 162.5mm;
  height: 16mm;
  border-bottom: 1px solid red;
}

.content-table-item5 {
  display: flex;
  width: 162.5mm;
  height: 18.3mm;
  border-bottom: 1px solid red;
}

.left-column {
  display: grid;
  place-items: center; /* 同时实现水平和垂直居中 */
  border-right: 0.1mm solid red;
  width: 18mm;
  height: 100%;
  writing-mode: vertical-rl;
}

.file-title {
  display: grid;
  place-items: center; /* 同时实现水平和垂直居中 */
  border-right: 0.1mm solid red;
  width: 18mm;
  height: 100%;
  padding: 0;
  margin: 0;
  text-align: center;
  white-space: pre-line;
}

.left-column2 {
  display: grid;
  place-items: center; /* 同时实现水平和垂直居中 */
  border-right: 0.1mm solid red;
  width: 18mm;
  height: 100%;
  padding: 0;
  margin: 0;
  text-align: center;
  white-space: pre-line;
}

.footer {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  font-family: 楷体, sans-serif;
}
</style>
